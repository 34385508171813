import './config/bootstrap';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { Dialog } from 'vant';
import vant from '@/features/common/libs/unvant';

//全局挂载方法到原型
import api from '@/utils/apis';

import stopChangeFontSize from '@/features/weixin/utils/stopChangeFontSize';
stopChangeFontSize();

import authCheck from '@/features/auth/check';
import pinia from './pinia';

import '@/features/common/utils/disableZoom';
import vDrag from '@/features/common/utils/vDrag';
import useMeStore from '@/features/auth/useMeStore';

const app = createApp(App);
app.use(pinia).use(vant);

const meStore = useMeStore();
app.config.globalProperties.$isDst = () => meStore.isDst;
app.config.globalProperties.$api = api;
app.directive('drag', vDrag);

authCheck()
  .then(() => {
    app.use(router);
    router.isReady().then(() => app.mount('#app'));
  })
  .catch((e) => {
    if (+e.status === 402 && e.message) {
      location.replace('/?#/update?t=' + e.message);
      return;
    }
    if (!e) return;
    Dialog.alert({ message: e?.message || e }).then(() => {
      location.reload();
    });
    throw e;
  });
