function dragEl(el) {
  let positionParams = {
    x: 0,
    y: 30,
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
  };
  el.addEventListener('touchstart', function (e) {
    positionParams.startX = e.touches[0].pageX;
    positionParams.startY = e.touches[0].pageY;
  });
  el.addEventListener('touchend', function () {
    positionParams.x = positionParams.endX;
    positionParams.y = positionParams.endY;
    positionParams.startX = 0;
    positionParams.startY = 0;
  });
  el.addEventListener('touchmove', function (e) {
    if (e.touches.length > 0) {
      let offsetX = e.touches[0].pageX - positionParams.startX;
      let offsetY = e.touches[0].pageY - positionParams.startY;
      let x = positionParams.x - offsetX;
      let y = positionParams.y - offsetY;
      if (x + el.offsetWidth > document.documentElement.offsetWidth) {
        x = document.documentElement.offsetWidth - el.offsetWidth;
      }
      if (y + el.offsetHeight > document.documentElement.offsetHeight) {
        y = document.documentElement.offsetHeight - el.offsetHeight;
      }
      if (x < 0) {
        x = 0;
      }
      if (y < 0) {
        y = 0;
      }
      el.style.right = x + 'px';
      el.style.bottom = y + 'px';
      positionParams.endX = x;
      positionParams.endY = y;
      e.preventDefault();
    }
  });
}

export default {
  mounted(el) {
    dragEl(el);
  },
  // vue2
  bind(el) {
    dragEl(el);
  },
};
