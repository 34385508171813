import { siteTitle } from '@/config/env';
import { stu, coa } from '@/config/origin';
import useMeStore from '@/features/auth/useMeStore';
import { updateShare } from '@/features/weixin/jweixin';

const meStore = useMeStore();

/*  全局分享方案 */
export let shareAll = () => {
  return updateShare(
    siteTitle + '实景视频', // 分享标题
    '科目三实景视频课程，在手机上提前熟悉考场和考试规则，提高效率', // 分享描述
    'https://xieyehui.gitee.io/driving_shareimg/share.jpg', // 分享图标
    stu.share + '/#/?uid=' + meStore.shareId // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
  // wx.hideMenuItems({
  //   menuList: [
  //     'menuItem:share:QZone',
  //     'menuItem:share:qq',
  //     'menuItem:copyUrl',
  //     'menuItem:share:email',
  //     'menuItem:openWithQQBrowser',
  //     'menuItem:openWithSafari',
  //   ],
  // });
};
/*  区代分享方案 */
export let shareAgent = () => {
  return updateShare(
    '区域代理', // 分享标题
    '科目三实景视频课程，在手机上提前熟悉考场和考试规则，提高效率', // 分享描述
    'https://xieyehui.gitee.io/driving_shareimg/share.jpg', // 分享图标
    stu.share + '/#/?uid=' + meStore.shareId // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
  // wx.hideMenuItems({
  //   menuList: [
  //     'menuItem:share:QZone',
  //     'menuItem:share:qq',
  //     'menuItem:copyUrl',
  //     'menuItem:share:email',
  //     'menuItem:openWithQQBrowser',
  //     'menuItem:openWithSafari',
  //     'menuItem:share:appMessage',
  //     'menuItem:share:timeline',
  //   ],
  // });
};
/*  课程分享方案 */
export let shareLesson = (lessonInfo) => {
  return updateShare(
    lessonInfo.share_title || lessonInfo.title, // 分享标题
    lessonInfo.share_intro, // 分享描述
    lessonInfo.logo + '?x-oss-process=image/resize,m_fixed,h_230,w_375', // 分享图标
    `${stu.share}/#/Video/${lessonInfo.id}?uid=${meStore.shareId}` // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
/*  教练二维码code分享 */
export let shareCode = (qrcode_id) => {
  return updateShare(
    siteTitle + '实景视频', // 分享标题
    '科目三实景视频课程，在手机上提前熟悉考场和考试规则，提高效率', // 分享描述
    'https://xieyehui.gitee.io/driving_shareimg/share.jpg', // 分享图标
    `${stu.share}/#/Code?qrcode_id=${qrcode_id}&uid=${meStore.shareId}` // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
/*  教练一键分享 */
export let coaShareAll = (codeUrl) => {
  return updateShare(
    siteTitle + '教练精品课程合集', // 分享标题
    '邀请您一起开通精品课程', // 分享描述
    coa.api + '/coach/img/onekey/shareall.png', // 分享图标
    codeUrl // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
/*  颁发奖状 */
export let shareAward = (codeUrl) => {
  return updateShare(
    siteTitle, // 分享标题
    '恭喜您顺利通过考试', // 分享描述
    coa.api + '/coach/img/onekey/shareall.png', // 分享图标
    codeUrl // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
/*  邀请教练 */
export let coaInvite = (codeUrl) => {
  return updateShare(
    '邀请你成为掌上驾考的分销员', // 分享标题
    '注册分销员，一起分享', // 分享描述
    coa.api + '/coach/img/invite/winvite.png', // 分享图标
    codeUrl // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
/*  分享给学员 */
export let shareStu = (codeUrl, lessonInfo) => {
  return updateShare(
    lessonInfo.share_title || lessonInfo.title, // 分享标题
    lessonInfo.share_intro, // 分享描述
    lessonInfo.logo, // 分享图标
    codeUrl // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
/*  分享招聘 */
export let shareJob = (info) => {
  return updateShare(
    '招聘' + info.title, // 分享标题
    info.content, // 分享描述
    'https://xieyehui.gitee.io/driving_shareimg/share.jpg', // 分享图标
    `${coa.share}/#/c_recruitdetails?push_id=${info.id}&uid=${meStore.shareId}` // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
/*  分享车辆 */
export let shareCar = (info, images) => {
  return updateShare(
    '出售' + info.title, // 分享标题
    info.remarks, // 分享描述
    images[0]?.path || '', // 分享图标
    `${coa.share}/#/c_makedetails?push_id=${info.id}&uid=${meStore.shareId}` // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
/*  分享培训 */
export let shareTrain = (info) => {
  return updateShare(
    '出售' + info.title, // 分享标题
    info.remarks, // 分享描述
    'https://xieyehui.gitee.io/driving_shareimg/share.jpg', // 分享图标
    `${coa.share}/#/c_traindetails?push_id=${info.id}&uid=${meStore.shareId}` // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  );
};
