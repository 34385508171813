import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import { shareAll, shareAgent } from '@/utils/utils';
import { Dialog } from 'vant';
import useMeStore from '@/features/auth/useMeStoreAdapter';
import setTitle from '@/features/common/utils/setTitle';

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if (to.path.match(/home|myVideos/i)) return null;
    return { top: 0 };
  },
});

const meStore = useMeStore();

router.beforeEach((to, from, next) => {
  const shareNext = () => {
    if (to.query.uid && to.query.uid === meStore.shareId) return next();
    return next({
      path: to.path,
      query: {
        ...to.query,
        uid: meStore.shareId,
      },
    });
  };

  let whiteList = ['update'];
  if (whiteList.includes(to.name)) return next();

  let userType = meStore.me.user_type;
  let isAgent = meStore.isAgent;

  if (to.meta.isAgent && isAgent) {
    return shareNext();
  }
  if (to.meta.userType) {
    const allowedEnter = to.meta.userType.map(Math.floor).includes(userType);
    if (allowedEnter) return shareNext();

    return Dialog.alert({
      title: '提示',
      message: '抱歉，您没有权限进入此页面',
      confirmButtonText: '返回首页',
    }).then(() => {
      // shareNext('/');
      if (to.query.uid && to.query.uid === meStore.shareId) return next();
      next({
        path: '/',
        query: {
          ...to.query,
          uid: meStore.shareId,
        },
      });
    });
  }
  shareNext();
});
router.afterEach((to) => {
  setTitle(to.meta?.title);
  //全局分享和隐藏
  to.meta.isAgent ? shareAgent() : shareAll();
});

export default router;
