export const fetchManifest = () => {
  if (location.port) return;
  const baseUrl = '';
  fetch(baseUrl + '/manifest.json?' + Date.now())
    .then((res) => {
      if (res.status !== 200) throw new Error(res.statusText);
      return res.text();
    })
    .then((res) => {
      res
        .match(/(\/[\w.]+){2,}(js|css)/g)
        .forEach((i) => createPrefetchLink(baseUrl + i));
    });
};
export default fetchManifest;

// setTimeout(fetchManifest, 1000);

export const createPrefetchLink = (href) => {
  if (document.querySelector(`[src="${href}"]`)) return;
  if (document.querySelector(`[href="${href}"]`)) return;
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = href.match(/js$/) ? 'script' : 'style';
  link.href = href;
  link.onload = () => link.remove();
  document.head.appendChild(link);
};
