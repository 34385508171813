import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import useMeStore from '@/features/auth/useMeStore';
import WeixinAuthorizeUserInfoMask from '@/components/weixinAuthorizeUserInfoMask';
import integralSignMask from '@/components/integralSignMask';
import prefetch from '@/features/common/utils/prefetch';
import { onMounted } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const meStore = useMeStore();
    onMounted(() => {
      prefetch();
    });
    const isDst = meStore.isDst;
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view, {
        class: _normalizeClass({
          isDst: _unref(isDst)
        })
      }, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, {
          include: /home/i,
          max: 1
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
        _: 1
      }, 8, ["class"]), _createVNode(_unref(WeixinAuthorizeUserInfoMask)), _createVNode(_unref(integralSignMask))], 64);
    };
  }
};